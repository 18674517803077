import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { MultiCityWeatherChart, WeatherLocation } from "./MultiCityWeatherChart";
const { Search } = Input;

export const MultiCityWeather = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newAddress,setNewAddress] = useState("");
  const [weatherLocations, setWeatherLocations] = useState<WeatherLocation[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const lookupAddress = (address: string) => {
    const shortAddress = address.substring(address.search(',')+1,address.length).trim();
    fetch(
        `https://api.michaelsvanbeek.com/forecastForAddress?address=${encodeURIComponent(address)}`,
        {
          mode:'cors'
        }
      )
      .then(response => response.json())
      .then(response => {
        setIsLoading(false);
        if('error' in response) {
          errorNotification(response['error']);
        } else {
          setWeatherLocations([
            ...weatherLocations, 
            new WeatherLocation(shortAddress, response.lat, response.lon, response.forecastHourlyData)
          ]);
        }
      })
      .catch((error) => {
        errorNotification(error.message);
        setIsLoading(false);
      });
  }

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter') {
      addNewAddressToLocations();
    }
  }

  const addNewAddressToLocations = () => {
    setIsLoading(true);
    lookupAddress(newAddress);
    setNewAddress("");
  }

  const errorNotification = (errorMessage: String) => {
    api.error({
      message: `Error`,
      description: <div>{errorMessage}</div>,
    });
  };

  return (
    <div className="content">
      {contextHolder}
      <Search 
        type="text"
        placeholder="Full Address"
        enterButton="Add Address"
        value={newAddress}
        onChange={(event) => setNewAddress(event.target.value)}
        onPressEnter={keyDownHandler}
        onSearch={addNewAddressToLocations}
        loading={isLoading}
      />
      <MultiCityWeatherChart weatherLocations={weatherLocations} />
    </div>
  );
}

export default MultiCityWeather;