import React, { FC } from 'react';
import {
  Link,
  useLocation,
} from "react-router-dom";
import { useDarkMode } from 'usehooks-ts';
import { ConfigProvider, Menu, Switch, theme } from 'antd';
import type { MenuProps } from 'antd';
import { Router, routeKeys } from './components/Router';
import './App.css';

const App: FC = () => {
  const { isDarkMode, toggle } = useDarkMode();
  const location = useLocation();
  const locationKey = routeKeys.get(location.pathname)!;

  const menuItems: MenuProps['items'] = [
    {
      label: <Link to="/">Home</Link>,
      key: 'home',
    },
    {
      label: <Link to="/sun">Sun</Link>,
      key: 'sun',
    },
    {
      label: <Link to="/weather">Weather</Link>,
      key: 'weather',
    },
    {
      label: <Switch checkedChildren="Dark" unCheckedChildren="Light" checked={isDarkMode} onChange={toggle} />,
      key: ""
    }
  ];
  
  return (
    <div 
      className="App" 
      style={isDarkMode ? {backgroundColor: "#000", color: "#fff"} : {backgroundColor: "#fff", color: "#000"}}
    >
      <ConfigProvider theme={isDarkMode ? { algorithm: theme.darkAlgorithm } : {}}>
        <Menu mode="horizontal" items={menuItems} selectedKeys={[locationKey]} />
        <Router />
      </ConfigProvider>
    </div>
  )
};

export default App;