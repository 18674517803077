import React from 'react';
import {
  Routes, Route,
} from "react-router-dom";
import { Home } from "./Home";
import { SunStats } from "./SunStats";
import { MultiCityWeather } from "./MultiCityWeather";

export const routeKeys: Map<string, string> = new Map<string, string>(
  [
    ['/','home'],
    ['/sun','sun'],
    ['/weather','weather'],
  ]
);

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="sun" element={<SunStats />}/>
      <Route path="weather" element={<MultiCityWeather />}/>
    </Routes>
  );
};

export default Router;
