import React from 'react';
import { useDarkMode, useElementSize } from 'usehooks-ts';
import { 
    Label, 
    Legend,
    Line,
    LineChart, 
    ResponsiveContainer,
    Tooltip,
    XAxis, 
    YAxis,
  } from 'recharts';

const LIGHT_COLOR = "#fff";
const DARK_COLOR = "#000";

const dateFormat = (date: Date) => {
  if(date instanceof Date) {
    let hour = date.getHours()%12;
    if(hour === 0) {
      hour = 12;
    }
    const amPm = date.getHours() < 12 ? "AM" : "PM";
    return `${date.getMonth()+1}/${date.getDate()} ${hour} ${amPm}`;
  }
  else {
    return '';
  }
}

export type HourlyForecastPoint = {
  endTime: string;
  number: number;
  startTime: string;
  temperature: number;
}

export class WeatherLocation {
  address: string;
  lat: Number;
  lon: Number;
  hourlyForecast: HourlyForecastPoint[];

  constructor(address: string, lat: number, lon: number, hourlyForecast: HourlyForecastPoint[]) {
      this.address = address;
      this.lat = lat;
      this.lon = lon;
      this.hourlyForecast = hourlyForecast;
  }
};

type MultiCityWeatherChartProps = {
    weatherLocations: WeatherLocation[]
  }
  
export const MultiCityWeatherChart = (props: MultiCityWeatherChartProps) => {
  const { isDarkMode } = useDarkMode();
  const [squareRef, { width, height }] = useElementSize();
  const weatherLocations = props.weatherLocations;

  const colors = [
    "#0275d8",
    "#5cb85c",
    "#f0ad4e",
    "#d9534f",
    "#5bc0de",
  ];

  const ForecastLine = (index: number, loc: WeatherLocation) => {
    return (
      <Line 
        type="monotone" 
        key={loc.address} 
        dot={false} 
        dataKey={loc.address} 
        label={loc.address} 
        stroke={colors[index % colors.length]} 
      />
    );
  }

  // Structure data for Recharts - array of objects, key per line
  const data: Record<string,any>[] = [];
  const lines: JSX.Element[] = [];
  for(let i=0; i<weatherLocations.length; i++) {
    const loc = weatherLocations[i];
    lines.push(ForecastLine(i,loc));
    for(let j=0; j<loc.hourlyForecast.length; j++){
      const p = loc.hourlyForecast[j];
      if(i===0){
        data[j] = {
          'date': new Date(p.startTime),
          [loc.address]: p.temperature
        };
      } else {
        data[j] = {
          ...data[j],
          [loc.address]: p.temperature
        }
      }
    }
  }

  const xAxisTicks = data.map(d => d['date']).filter((d: Date) => [0,12].includes(d.getHours()));

  return (
    <div className="content" ref={squareRef}>
      <ResponsiveContainer height={height-32}>
        <LineChart data={data} syncId="multiCityWeather">
          <XAxis 
            dataKey="date" 
            tickFormatter={dateFormat}
            ticks={xAxisTicks}
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          >
            <Label 
              value="Date" 
              position="insideBottom" 
              offset={-4} 
              style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}} 
            />
          </XAxis>
          <YAxis 
            unit="°F"
            domain={['auto', 'auto']}
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          >
            <Label 
              value="Temperature" 
              angle={-90} 
              position="left" 
              offset={-5} 
              style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}} 
            />
          </YAxis>
          {lines}
          <Legend />
          <Tooltip 
            contentStyle={{
              color: isDarkMode ? LIGHT_COLOR : DARK_COLOR,
              backgroundColor: isDarkMode ? DARK_COLOR : LIGHT_COLOR
            }}
            labelFormatter={dateFormat} 
            formatter={(v) => `${v}°F`} 
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}