import React from "react";
import { Typography } from 'antd';
const { Paragraph, Title } = Typography;

export const Home = () => {
    return (
      <div>
        <Title>Michael Van Beek</Title>
        <Paragraph>
          <ul>
            <li>Data visualization enthusiast</li>
            <li>Software Performance Engineering Manager</li>
            <li>Photographer</li>
            <li>Amature Athlete</li>
          </ul>
        </Paragraph>
      </div>
    );
  };
  
  export default Home;
  