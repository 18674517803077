import React, { useEffect, useState } from 'react';
import { useDarkMode, useElementSize } from 'usehooks-ts';
import { 
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  ReferenceLine, 
  ReferenceDot,
  Tooltip, 
  XAxis, 
  YAxis,
} from 'recharts';
import * as SunCalc from "suncalc";

const DEGREES_PER_RADIAN = 57.2958;
const MS_PER_MINUTE = 1000*60;
const HOURS_PER_DAY = 24;
const MS_PER_DAY = MS_PER_MINUTE*60*HOURS_PER_DAY;
const LIGHT_COLOR = "#fff";
const DARK_COLOR = "#000";

const getHour = (dt: Date) => {
  return ((dt.getTime() - (dt.getTimezoneOffset() * MS_PER_MINUTE)) % MS_PER_DAY) / MS_PER_DAY * HOURS_PER_DAY;
}

const hourFormatter = (value: any, name: any, props: any) => {
  const minDecimal = value % 1;
  const minInt = Math.floor(minDecimal * 60);
  const hourInt = Math.floor(value);
  if(minInt >= 10) {
    return hourInt + ":" + minInt;
  } else {
    return hourInt + ":0" + minInt;
  }
  
}

const getAngle = (dt: Date, latitude: number, longitude: number) => {
  return SunCalc.getPosition(dt,latitude,longitude).altitude * DEGREES_PER_RADIAN;
}

export const SunStats = () => {
  const { isDarkMode } = useDarkMode();
  const [squareRef, { width, height }] = useElementSize();
  const [latLng, setLatLng] = useState([0.0,0.0])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLng([position.coords.latitude,position.coords.longitude])
      });
    } else {
      // print error
    }
  }, []);

  const today = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 183);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 183);
  var allDates = [];
  for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const day = new Date(d);
    const times = SunCalc.getTimes(day, latLng[0], latLng[1]);
    var data = {
      "Date":day.toLocaleDateString(),
      "Night End":getHour(times.nightEnd),
      "Nautical Dawn": getHour(times.nauticalDawn),
      "Dawn":getHour(times.dawn),
      "Sunrise":getHour(times.sunrise),
      "Solar Noon":getHour(times.solarNoon),
      "Solar Noon Angle":getAngle(times.solarNoon,latLng[0],latLng[1]),
      "Golden Hour": getHour(times.goldenHour),
      "Golden Hour End": getHour(times.goldenHourEnd),
      "Sunset":getHour(times.sunset),
      "Dusk":getHour(times.dusk),
      "Nautical Dusk": getHour(times.nauticalDusk),
      "Night":getHour(times.night),
      "Nadir Angle":getAngle(times.nadir,latLng[0],latLng[1]),
      "Hours in Day":getHour(times.sunset) - getHour(times.sunrise),
      "Hours in Civil Day":getHour(times.dusk) - getHour(times.dawn)
    };
    allDates.push(data);
  }
  const allDatesForStats = [...allDates];
  allDatesForStats.sort((a,b) => a['Hours in Day']-b['Hours in Day']);
  const maxHoursInDayDate = allDatesForStats[allDatesForStats.length-1].Date;
  const maxHoursInDay = allDatesForStats[allDatesForStats.length-1]['Hours in Day'];
  const minHoursInDayDate = allDatesForStats[0].Date;
  const minHoursInDay = allDatesForStats[0]['Hours in Day'];
  allDatesForStats.sort((a,b) => a['Sunrise']-b['Sunrise']);
  const earliestSunrise = allDatesForStats[0].Sunrise;
  const earliestSunriseDate = allDatesForStats[0].Date;
  const latestSunrise = allDatesForStats[allDatesForStats.length-1].Sunrise;
  const latestSunriseDate = allDatesForStats[allDatesForStats.length-1].Date;
  allDatesForStats.sort((a,b) => a['Sunset']-b['Sunset']);
  const earliestSunset = allDatesForStats[0].Sunset;
  const earliestSunsetDate = allDatesForStats[0].Date;
  const latestSunset = allDatesForStats[allDatesForStats.length-1].Sunset;
  const latestSunsetDate = allDatesForStats[allDatesForStats.length-1].Date;

  const axisDates = allDates.map(d => d.Date).filter(d => new Date(d).getDate() == 1);
  
  return (
    <div className="content" ref={squareRef}>
      <ResponsiveContainer height={height/2}>
        <LineChart data={allDates} syncId="sunStats">
          <XAxis 
            dataKey="Date" 
            ticks={axisDates}
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          />
          <YAxis 
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
            reversed={true}
          >
            <Label value="Times" angle={-90} style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}} />
          </YAxis>
          <ReferenceLine x={today.toLocaleDateString()} stroke="green" strokeWidth={2}>
            <Label value="Today" fontSize={20} style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}} />
          </ReferenceLine>
          <ReferenceDot x={earliestSunriseDate} y={earliestSunrise} fill="red" r={4}>
            <Label position="bottom" offset={5} value="Earliest Sunrise" style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}/>
          </ReferenceDot>
          <ReferenceDot x={latestSunriseDate} y={latestSunrise} fill="red" r={4}>
            <Label position="bottom" offset={5} value="Latest Sunrise" style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}/>
          </ReferenceDot>
          <ReferenceDot x={earliestSunsetDate} y={earliestSunset} fill="blue" r={4}>
            <Label position="top" offset={5} value="Earliest Sunset" style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}/>
          </ReferenceDot>
          <ReferenceDot x={latestSunsetDate} y={latestSunset} fill="blue" r={4}>
            <Label position="top" offset={5} value="Latest Sunset" style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}/>
          </ReferenceDot>
          <Tooltip 
            contentStyle={{
              color: isDarkMode ? LIGHT_COLOR : DARK_COLOR,
              backgroundColor: isDarkMode ? DARK_COLOR : LIGHT_COLOR
            }}
            formatter={hourFormatter} 
          />

          <Line type="monotone" dataKey="Night End" dot={false} stroke="red" />
          {/* <Line type="monotone" dataKey="Nautical Dawn" dot={false} stroke="red" /> */}
          <Line type="monotone" dataKey="Dawn" dot={false} stroke="red" />
          <Line type="monotone" dataKey="Sunrise" dot={false} stroke="red" />
          <Line type="monotone" dataKey="Golden Hour End" dot={false} stroke="orange" />

          <Line type="monotone" dataKey="Golden Hour" dot={false} stroke="orange" />
          <Line type="monotone" dataKey="Sunset" dot={false} stroke="lightBlue" />
          <Line type="monotone" dataKey="Dusk" dot={false} stroke="blue" />
          {/* <Line type="monotone" dataKey="Nautical Dusk" dot={false} stroke="purple" /> */}
          <Line type="monotone" dataKey="Night" dot={false} stroke="purple" />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={height/6}>
        <LineChart data={allDates} syncId="sunStats">
          <XAxis 
            dataKey="Date" 
            ticks={axisDates}
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          />
          <YAxis 
            domain={[Math.floor(minHoursInDay),Math.ceil(maxHoursInDay)]} 
            interval="preserveStartEnd"
            tickFormatter={(value) => hourFormatter(value,undefined,undefined)} 
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          >
            <Label value="Hours" angle={-90} style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}} />
          </YAxis>
          <Line type="monotone" dataKey="Hours in Day" dot={false} stroke={isDarkMode? LIGHT_COLOR : DARK_COLOR} />
          <ReferenceDot x={maxHoursInDayDate} y={maxHoursInDay} fill={isDarkMode? LIGHT_COLOR : DARK_COLOR} r={4}>
            <Label position="bottom" offset={5} value="Longest Day" style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}/>
          </ReferenceDot>
          <ReferenceDot x={minHoursInDayDate} y={minHoursInDay} fill={isDarkMode? LIGHT_COLOR : DARK_COLOR} r={4}>
            <Label position="top" offset={5} value="Shortest Day" style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}/>
          </ReferenceDot>
          <Tooltip 
            contentStyle={{
              color: isDarkMode ? LIGHT_COLOR : DARK_COLOR,
              backgroundColor: isDarkMode ? DARK_COLOR : LIGHT_COLOR
            }}
            formatter={hourFormatter} 
          />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={height/3} >
        <LineChart data={allDates} syncId="sunStats">
          <XAxis 
            dataKey="Date" 
            ticks={axisDates}
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          />
          <YAxis 
            unit="°"
            tick={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            tickLine={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}}
            stroke={isDarkMode ? LIGHT_COLOR : DARK_COLOR}
          >
            <Label value="Angle" angle={-90} style={{fill: isDarkMode ? LIGHT_COLOR : DARK_COLOR}} />
          </YAxis>
          <Line type="monotone" dataKey="Solar Noon Angle" dot={false} stroke="red"/>
          <Line type="monotone" dataKey="Nadir Angle" dot={false} stroke="blue" />
          <Tooltip 
            contentStyle={{
              color: isDarkMode ? LIGHT_COLOR : DARK_COLOR,
              backgroundColor: isDarkMode ? DARK_COLOR : LIGHT_COLOR
            }}
            formatter={(v) => {
                const num = Number(v);
                const roundedNum = Math.round(num*100)/100;
                return `${roundedNum}°`;
              }}
            />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SunStats;
